@import '@/styles/variables.scss';


























































































































































































































































































































































































































































































































































































































































.drag-n-drop {
  min-height: 152px !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 3px !important;
  border: none !important;
  .v-alert {
    border-radius: 3px !important;
    &:before {
      background-color: white !important;
    }
    p {
      color: #505a68;
      &.text-sm-body-1 {
        line-height: 1.3 !important;
        letter-spacing: normal !important;
      }
    }
  }
  &__errors,
  &__progress {
    .v-alert__wrapper {
      flex: 1 !important;
      align-items: flex-start;
    }

    background-color: white;
    .v-alert__dismissible {
      position: absolute;
      top: 25px;
      right: 20px;
    }
    .v-alert__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      height: 100%;
    }
    .v-btn {
      position: absolute;
      top: 4px;
      right: 10px;
    }
  }
  &__progress {
    .v-alert__icon {
      display: none;
    }
  }
  &__zone {
    transition: all ease-in-out 0.3s;
    display: flex;
    &:hover,
    &.active {
      background: rgb(243, 243, 243) !important;
    }
  }
  @media only screen and (max-width: 959px) {
    .v-alert__icon {
      display: none;
    }
  }
}
.hide-input {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 1;
}
